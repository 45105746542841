// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require('expose-loader?$!expose-loader?jQuery!jquery');
require('expose-loader?toastr!toastr');

toastr.options.closeButton = true;

require([
  'jquery',
  'bootstrap',
  'jquery_ujs',
  'jquery_nested_form',
  'chosen',
  'minicolors',
  'jqueryFileUpload',
  'icheck',
  'async',
  'autocomplete_rails',
  'compatibility',
  'pdf',
  'slick',
  'flipclock',
  'datetimepicker',
  'moment',
  'circle_progress',
  'jquery_ui',
  'scrollTo',
  'jquery_cookie',
  'ckeditor_jquery',
  'mdl',
  'slideAndSwipe',
  'mainApp',
  'jspolyfill-array.prototype.find'
], (
  $,
  bootstrap,
  jquery_ujs,
  jquery_nested_form,
  chosen,
  minicolors,
  jqueryFileUpload,
  icheck,
  async,
  autocomplete_rails,
  compatibility,
  pdf,
  slick,
  flipclock,
  datetimepicker,
  moment,
  circle_progress,
  jquery_ui,
  scrollTo,
  jquery_cookie,
  ckeditor_jquery,
  mdl,
  slideAndSwipe,
  mainApp
) => {
  mdl.upgradeDom();
  const app = mainApp.getInstance();
});
